import { Link } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { Twitter, MessageCircle, BookOpen, Menu, X, Book } from "lucide-react";
import { FaReddit, FaTiktok } from 'react-icons/fa';
import { useState } from "react";

const Navbar = () => {
  const { publicKey } = useWallet();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Check if connected wallet is admin
  const isAdmin = publicKey?.toBase58() === "8rYNzisESAJZAJGZiyosNkVb1tbrWhsgQkLgavj6Ytyj";

  const navigationItems = [
    { path: "/swap", label: "SOLessSwap" },
    { path: "/solspace", label: "SOLspace" },
    { path: "/solarium", label: "SOLarium" },
    { path: "/community", label: "COMMUNITY", className: "text-green-400" },
    { path: "/founders-collection", label: "Soulie NFTs", className: "text-purple-400" },
    { path: "/docs", label: "Docs", icon: BookOpen },
    ...(isAdmin ? [{ path: "/admin", label: "ADMIN", className: "text-purple-400" }] : []),
  ];

  const SocialLinks = () => (
    <div className="flex items-center space-x-4">
      <a
        href="https://x.com/SOLessSystem"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-white transition-colors duration-200"
      >
        <Twitter className="h-5 w-5" />
      </a>
      <a
        href="https://t.me/SolessSystem"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-white transition-colors duration-200"
      >
        <MessageCircle className="h-5 w-5" />
      </a>
      <a
        href="https://reddit.com/r/soless"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-white transition-colors duration-200"
      >
        <FaReddit size={20} />
      </a>
      <a
        href="https://tiktok.com/@solesssystem"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-white transition-colors duration-200"
      >
        <FaTiktok size={20} />
      </a>
      <a
        href="https://medium.com/@team_94982"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 hover:text-white transition-colors duration-200"
      >
        <Book className="h-5 w-5" />
      </a>
    </div>
  );

    return (
      <nav className="fixed top-0 left-0 right-0 z-50 bg-black/50 backdrop-blur-lg border-b border-soless-blue/40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center h-16">
            {/* Left section with Logo and basic links */}
            <div className="flex-1 flex items-center space-x-6">
              <div className="flex items-center">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white md:hidden"
                >
                  {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                </button>
                <Link 
                  to="/" 
                  className="flex items-center transition-transform duration-200 hover:scale-105"
                >
                  <img 
                    src="/assets/images/logo.png" 
                    alt="SOLess Logo" 
                    className="h-8 w-auto"
                  />
                </Link>
              </div>
              <div className="hidden md:flex space-x-6">
                <Link to="/swap" className="text-gray-300 hover:text-white transition-colors duration-200">
                  SOLessSwap
                </Link>
                <Link to="/solspace" className="text-gray-300 hover:text-white transition-colors duration-200">
                  SOLspace
                </Link>
                <Link to="/solarium" className="text-gray-300 hover:text-white transition-colors duration-200">
                  SOLarium
                </Link>
              </div>
            </div>
    
            {/* Center section - Winter Carnival and Founders Collection */}
            <div className="flex-1 flex items-center justify-center space-x-8">
              <Link to="/community" className="text-green-400 hover:text-green-300 transition-colors duration-200 whitespace-nowrap">
                Winter Carnival
              </Link>
              <Link to="/founders-collection" className="text-purple-400 hover:text-purple-300 transition-colors duration-200 whitespace-nowrap">
                Founders Collection
              </Link>
            </div>
    
            {/* Right section - Social Links and Docs */}
            <div className="flex-1 flex items-center justify-end space-x-6">
              <div className="hidden md:block">
                <SocialLinks />
              </div>
              <Link 
                to="/docs" 
                className="text-gray-300 hover:text-white transition-colors duration-200 flex items-center gap-1 ml-6"
              >
                <BookOpen className="h-4 w-4" />
                Docs
              </Link>
            </div>
          </div>
        </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 bg-black/90">
            {navigationItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-black/50 
                  transition-colors duration-200 ${item.className || ""}`}
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center gap-2">
                  {item.icon && <item.icon className="h-4 w-4" />}
                  {item.label}
                </div>
              </Link>
            ))}
            <div className="px-3 py-2">
              <SocialLinks />
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;