// src/lib/api/users.ts
import { apiClient } from "./client";
import { UserProfile } from "./types";

interface StreakUpdateResponse {
  success: boolean;
  currentStreak: number;
  lastUpdated: string;
}

export const usersApi = {
  getUser: (wallet: string) =>
    apiClient.post<UserProfile>("/users/get", { wallet }).then((res) => res.data),

  updateStreak: (wallet: string, platform: string) =>
    apiClient
      .post<StreakUpdateResponse>("/users/streaks/update", { wallet, platform })
      .then((res) => res.data),
};
