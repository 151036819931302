// src/lib/api/invites.ts
import { apiClient } from './client';
import { Invite } from './types';

interface CreateInviteResponse {
  invite: Invite;
  success: boolean;
}

interface ClaimInviteResponse {
  success: boolean;
  message: string;
}

export const invitesApi = {
  createInvite: (wallet: string) =>
    apiClient
      .post<CreateInviteResponse>('/invites/create', { wallet })
      .then(res => res.data),

  claimInvite: (code: string, newUserWallet: string) =>
    apiClient
      .post<ClaimInviteResponse>('/invites/claim', { code, newUserWallet })
      .then(res => res.data),

  getUserInvites: (wallet: string) =>
    apiClient
      .get<Invite[]>(`/invites/${wallet}`)
      .then(res => res.data),
};
