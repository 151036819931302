// app/src/App.tsx
import { BrowserRouter } from "react-router-dom";
import WalletContextProvider from "./components/WalletContextProvider"; // Update this import
import routes from "./routes";
import Navbar from "./components/Navbar";
import { useRoutes } from "react-router-dom";
import { Suspense } from "react";
import ErrorBoundary from "./components/ErrorBoundary";
import FloatingWalletModule from './components/FloatingWalletModule';

const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="text-soless-blue animate-spin">Loading...</div>
  </div>
);

const AppRoutes = () => {
  const element = useRoutes(routes);

  if (!element) {
    return <div>Page not found</div>;
  }

  return <Suspense fallback={<LoadingSpinner />}>{element}</Suspense>;
};

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <WalletContextProvider>  {/* Updated this line */}
          <div className="min-h-screen bg-black font-space text-white">
            <Navbar />
            <FloatingWalletModule />
            <main className="pt-12">
              <AppRoutes />
            </main>
          </div>
        </WalletContextProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;