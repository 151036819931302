import { useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { isAdminWallet } from "../utils/wallet";

// Use the same constants as usePresale.ts
const TREASURY_WALLET = new PublicKey(
  "668N9L9tdjKwEW26Zg5gtMKVs5PA8x1Tp5FHeEZkj8i2"
);
const PRESALE_START = new Date("2025-01-01T09:00:00Z");
const PRESALE_END = new Date("2025-01-10T23:59:59Z");
const MIN_CONTRIBUTION = 0.1;
const MAX_CONTRIBUTION = 5;

const Admin = () => {
  const { connection } = useConnection();
  const { connected, publicKey } = useWallet();
  const [balance, setBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const isAuthorized = isAdminWallet(publicKey?.toBase58());

  const fetchBalance = async () => {
    try {
      const bal = await connection.getBalance(TREASURY_WALLET);
      setBalance(bal / LAMPORTS_PER_SOL);
    } catch (error) {
      console.error("Error fetching balance:", error);
      setBalance(null);
    }
  };

  useEffect(() => {
    if (connected && isAuthorized) {
      fetchBalance();
      const interval = setInterval(fetchBalance, 10000); // Refresh every 10 seconds
      return () => clearInterval(interval);
    }
  }, [connected, isAuthorized]);

  // Show connect wallet or unauthorized message
  if (!connected || !isAuthorized) {
    return (
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-soless-blue to-soless-purple bg-clip-text text-transparent">
          Admin Access
        </h1>
        <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
          {!connected ? (
            <div>
              <p className="text-xl text-gray-300 mb-4">
                Connect your wallet to access admin panel
              </p>
              <WalletMultiButton className="!bg-gradient-to-r !from-soless-blue !to-soless-purple hover:!opacity-90" />
            </div>
          ) : (
            <div className="text-xl text-red-500">
              <p>Unauthorized access</p>
              {publicKey && (
                <p className="text-sm mt-2">Wallet: {publicKey.toBase58()}</p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-soless-blue to-soless-purple bg-clip-text text-transparent">
        Presale Admin
      </h1>

      {/* Stats Dashboard */}
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mb-8">
        <div className="bg-black/30 p-6 rounded-xl border border-soless-blue/40">
          <h3 className="text-lg font-semibold text-soless-blue mb-2">
            Total Raised
          </h3>
          <p className="text-2xl font-bold">
            {balance !== null ? balance.toFixed(2) : "Loading..."} SOL
          </p>
        </div>
      </div>

      {/* Presale Settings */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40 mb-8">
        <h2 className="text-2xl font-bold mb-4 text-soless-blue">
          Presale Settings
        </h2>
        <ul className="text-gray-300 space-y-2">
          <li>Start time: {PRESALE_START.toUTCString()}</li>
          <li>End time: {PRESALE_END.toUTCString()}</li>
          <li>Minimum contribution: {MIN_CONTRIBUTION} SOL</li>
          <li>Maximum contribution: {MAX_CONTRIBUTION} SOL</li>
          <li>Treasury wallet: {TREASURY_WALLET.toString()}</li>
        </ul>
      </div>

      {/* Presale Status */}
      <div className="bg-black/30 p-8 rounded-xl border border-soless-blue/40">
        <h2 className="text-2xl font-bold mb-4 text-soless-blue">
          Presale Status
        </h2>
        <div className="text-gray-300">
          <p>
            Status: <span className={getStatusColor()}>{getStatusText()}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

// Helper functions for status
const getStatusText = () => {
  const now = new Date();
  if (now < PRESALE_START) return "Not Started";
  if (now > PRESALE_END) return "Ended";
  return "Active";
};

const getStatusColor = () => {
  const now = new Date();
  if (now < PRESALE_START) return "text-yellow-500";
  if (now > PRESALE_END) return "text-red-500";
  return "text-green-500";
};

export default Admin;
