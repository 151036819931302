// app/src/routes/index.tsx
import { RouteObject } from "react-router-dom";
import Home from "../pages/Home";
import Presale from "../pages/Presale";
import SOLspace from "../pages/Solspace";
import Solarium from "../pages/Solarium";
import Swap from "../pages/Swap";
import Admin from "../pages/Admin";
import Documentation from "../pages/Documentation";
import PioneerPartnership from "../pages/pioneer-partnership";  
import AlphaRelease from "../pages/AlphaRelease";
import MarketingApplicationForm from "../pages/MarketingApplicationForm";
import FoundersCollection from "../pages/FoundersCollection";
import TradingBotAccess from "../pages/TradingBotAccess";
import MintPage from "../pages/MintPage";
import NFTRewardsPage from "../pages/NFT-Benefits";
import Community from "../pages/Community";
import Register from "../pages/Register";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/presale",
    element: <Presale />,
  },
  {
    path: "/solspace",
    element: <SOLspace />,
  },
  {
    path: "/solarium",
    element: <Solarium />,
  },
  {
    path: "/swap",
    element: <Swap />,
  },
  {
    path: "/docs",
    element: <Documentation />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/pioneer-partnership",
    element: <PioneerPartnership />,
  },
  {
    path: "/alpha-release",
    element: <AlphaRelease />,
  },
  {
    path: "/marketing-application",
    element: <MarketingApplicationForm />,
  },
  {
    path: "/founders-collection",
    element: <FoundersCollection />,
  },
  {
    path: "/trading-bot-access",
    element: <TradingBotAccess />,
  },
  {
    path: "/mint",
    element: <MintPage />,
  },
  {
    path: "/NFT-Benefits",
    element: <NFTRewardsPage />,
  },
  {
    path: "/community",
    element: <Community />,
  },
  {
    path: "/register",
    element: <Register />,
  },
];

export default routes;