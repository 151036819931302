// src/lib/api/stats.ts
import { apiClient } from "./client";
import { UserStats, LeaderboardOptions } from "./types";

interface LeaderboardEntry {
  wallet: string;
  points: number;
  rank: number;
}

export const statsApi = {
  getUserStats: (wallet: string) =>
    apiClient.get<UserStats>(`/stats/${wallet}`).then((res) => res.data),

  getLeaderboard: (options?: LeaderboardOptions) =>
    apiClient
      .get<LeaderboardEntry[]>("/stats/leaderboard", { params: options })
      .then((res) => res.data),

  getPlatformLeaderboard: (
    platform: string,
    options?: Omit<LeaderboardOptions, "platform">
  ) =>
    apiClient
      .get<LeaderboardEntry[]>(`/stats/leaderboard/${platform}`, {
        params: options,
      })
      .then((res) => res.data),
};
