import { useState } from "react";
import { Share2, Copy, Check } from "lucide-react";
import { invitesApi } from "../lib/api";

interface InviteSectionProps {
  wallet: string;
}

export const InviteSection = ({ wallet }: InviteSectionProps) => {
  const [inviteLink, setInviteLink] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const generateInviteLink = async () => {
    setLoading(true);
    setError(null);
    try {
      const { invite } = await invitesApi.createInvite(wallet);
      setInviteLink(invite.code);
    } catch (error: any) {
      console.error("Error generating invite:", error);
      setError(error?.response?.data?.error || "Failed to generate invite");
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    if (!inviteLink) return;
    try {
      await navigator.clipboard.writeText(inviteLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
      setError("Failed to copy to clipboard");
    }
  };

  return (
    <div className="bg-black/50 backdrop-blur-lg p-6 rounded-lg border border-[#16DFED]/40">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-bold text-[#16DFED]">Invite Friends</h3>
        <button
          onClick={generateInviteLink}
          disabled={loading}
          className={`bg-[#16DFED] hover:bg-[#16DFED]/80 text-black px-4 py-2 rounded-md flex items-center space-x-2 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <Share2 className="h-4 w-4" />
          <span>{loading ? "Generating..." : "Generate Link"}</span>
        </button>
      </div>

      {error && <div className="text-red-400 text-sm mb-4">{error}</div>}

      {inviteLink && (
        <div className="mt-4">
          <div className="flex items-center space-x-2">
            <input
              type="text"
              value={inviteLink}
              readOnly
              className="flex-1 bg-black/30 border border-[#16DFED]/40 rounded-md px-4 py-2 text-white focus:border-[#16DFED] focus:ring-1 focus:ring-[#16DFED]"
            />
            <button
              onClick={copyToClipboard}
              className="bg-[#16DFED]/20 hover:bg-[#16DFED]/30 p-2 rounded-md"
              title="Copy to clipboard"
            >
              {copied ? (
                <Check className="h-5 w-5 text-green-400" />
              ) : (
                <Copy className="h-5 w-5 text-[#16DFED]" />
              )}
            </button>
          </div>
          <p className="text-gray-400 text-sm mt-2">
            Earn 10 points for each friend who joins using your link!
          </p>
        </div>
      )}
    </div>
  );
};
