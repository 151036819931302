import React from "react";
import { Link } from "react-router-dom";
import { BookOpen, Newspaper, FileText } from "lucide-react";

interface DocumentItem {
  title: string;
  description: string;
  date: string;
  link?: string;
  url?: string;
  version?: string;
  category?: string;
  readTime?: string;
}

interface DocSectionProps {
  title: string;
  items: DocumentItem[];
  icon: React.ComponentType<any>;
}

const Documentation = () => {
  const whitepapers: DocumentItem[] = [
    {
      title: "SOLess: A Gasless Decentralized Exchange on Solana",
      description:
        "A comprehensive overview of SOLess's gasless DEX, featuring unique tokenomics and Soulie mascot.",
      link: "/whitepapers/SOLess%20Swap%20v3.pdf",
      version: "V1.2",
      date: "October 2024",
    },
    {
      title: "SOLspace: A Blockchain-Based Social Media Platform",
      description:
        "Overview of decentralized social platform with content ownership and NFT integration.",
      link: "/whitepapers/SOLspace%20Whitepaper%20V1.0.pdf",
      version: "V1.0",
      date: "November 2024",
    },
    {
      title: "SOLarium: A Vault for Valuable NFT Art",
      description:
        "Detailed analysis of SOLarium's NFT vault solution with value preservation features.",
      link: "/whitepapers/SOLarium%20Whitepaper%20V1.0.pdf",
      version: "V1.0",
      date: "November 2024",
    },
  ];

  const articles: DocumentItem[] = [
    {
      title: "SOLarium: Transforming NFT Ownership",
      description:
        "Deep dive into SOLarium's innovative approach to NFT liquidity and value preservation.",
      link: "/articles/SOLarium%20Article%201%20(LIQUIDITY).pdf",
      category: "NFT Innovation",
      date: "November 2024",
    },
    {
      title: "SOLspace: Revolutionizing Social Media",
      description:
        "How SOLspace is redefining content creation and ownership in social media.",
      link: "/articles/SOLspace%20Article%201%20(CREATORS).pdf",
      category: "Social Media",
      date: "November 2024",
    },
    {
      title: "SOLess.app: The Game-Changing Gasless DEX",
      description:
        "Exploring the impact of gasless transactions on meme token utility.",
      link: "/articles/SOLessSwap%20Article%201%20(MEME).pdf",
      category: "DeFi Innovation",
      date: "November 2024",
    },
    // Medium articles
    {
      title:
        "How SOLspace will Transform Social Discourse Through Source Verification",
      description:
        "By leveraging blockchain technology to integrate source verification and engagement tracking, SOLspace is not just imagining a better social media experience — it's actively building one.",
      url: "https://medium.com/@team_94982/how-solspace-will-transform-social-discourse-through-source-verification-and-transparent-engagement-ddcc445ae3ee",
      date: "November 2024",
      readTime: "5 min read",
    },
    // ... rest of your Medium articles ...
  ];

  const press: DocumentItem[] = [
    {
      title: "SOLess Ecosystem Announces Token Presale",
      description:
        "A comprehensive look at the SOLess ecosystem launch and presale announcement.",
      link: "/press/SOLess%20Press%20Release.pdf",
      date: "November 17, 2024",
    },
  ];

  const DocSection: React.FC<DocSectionProps> = ({
    title,
    items,
    icon: Icon,
  }) => (
    <section className="mb-12">
      <div className="flex items-center gap-2 mb-6">
        <Icon className="h-6 w-6 text-soless-blue" />
        <h2 className="text-2xl font-bold bg-gradient-to-r from-soless-blue to-soless-purple bg-clip-text text-transparent">
          {title}
        </h2>
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {items.map((item) => (
          <div
            key={item.title}
            className="bg-black/30 p-6 rounded-xl border border-soless-blue/40 hover:border-soless-blue transition-colors flex flex-col h-full"
          >
            <div className="flex-grow">
              <div className="flex justify-between items-start mb-2">
                {item.category && (
                  <div className="text-xs text-soless-blue">
                    {item.category}
                  </div>
                )}
                {item.version && (
                  <div className="text-xs text-soless-blue">{item.version}</div>
                )}
              </div>
              <h3 className="text-lg font-semibold text-gray-200 mb-2">
                {item.title}
              </h3>
              <p className="text-gray-300 text-sm mb-4">{item.description}</p>
              <div className="flex justify-between text-xs text-gray-400">
                <span>{item.date}</span>
                {item.readTime && <span>{item.readTime}</span>}
              </div>
            </div>
            {item.url ? (
              // External Medium article links
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-4 py-2 rounded-lg text-sm font-semibold hover:opacity-90 transition-opacity w-full text-center mt-4"
              >
                Read on Medium
              </a>
            ) : item.link?.startsWith("/") ? (
              // Internal document links
              <Link
                to={item.link}
                className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-4 py-2 rounded-lg text-sm font-semibold hover:opacity-90 transition-opacity w-full text-center mt-4"
              >
                Read
              </Link>
            ) : (
              // External document links
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-gradient-to-r from-soless-blue to-soless-purple px-4 py-2 rounded-lg text-sm font-semibold hover:opacity-90 transition-opacity w-full text-center mt-4"
              >
                Read
              </a>
            )}
          </div>
        ))}
      </div>
    </section>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {/* Banner */}
      <div className="mb-8">
        <img
          src="/assets/images/DocBanner.png"
          alt="SOLess Banner"
          className="w-full h-auto rounded-xl"
        />
      </div>

      {/* Main Content */}
      <div>
        <DocSection title="Whitepapers" items={whitepapers} icon={BookOpen} />
        <DocSection title="Articles" items={articles} icon={FileText} />
        <DocSection title="Press Releases" items={press} icon={Newspaper} />
      </div>

      {/* Soulie at bottom */}
      <div className="flex justify-center mt-12">
        <img
          src="/assets/images/PresaleSoulie.png"
          alt="Soulie"
          className="h-48 w-auto"
        />
      </div>
    </div>
  );
};

export default Documentation;
