// src/lib/api/client.ts
import axios from "axios";

const API_URL = import.meta.env.VITE_API_URL || "http://localhost:3001";

export const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  }
});

// Handle response errors
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Log the full error for debugging
    console.error("API Error:", {
      config: error.config,
      response: error.response,
      message: error.message,
    });

    if (error.response) {
      // Server responded with error status
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }

      // Create error with status code in message for easier checking
      const message = error.response.data?.error || error.response.statusText;
      return Promise.reject(new Error(`${error.response.status}: ${message}`));
    } else if (error.request) {
      // Request was made but no response received
      console.error("No response received:", error.request);
      return Promise.reject(new Error("No response from server"));
    } else {
      // Something happened in setting up the request
      console.error("Request setup error:", error.message);
      return Promise.reject(error);
    }
  }
);

// Export types for use in components
export type ApiResponse<T> = {
  data: T;
  status: number;
  message?: string;
};

export type ApiError = {
  status: number;
  message: string;
  errors?: Record<string, string[]>;
};
