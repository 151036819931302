// app/src/pages/Community.tsx
import { useWallet } from "@solana/wallet-adapter-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Trophy,
  Users,
  Star,
  Flame,
  Share2,
  MessageCircle,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { LoadingState } from "../components/LoadingState";
import { ErrorState } from "../components/ErrorState";
import { InviteSection } from "../components/InviteSection";
import { statsApi } from "../lib/api";

interface PlatformStats {
  points: number;
  rank: number;
  engagements: number;
  streak: number;
  history: {
    date: string;
    points: number;
  }[];
}

interface UserData {
  totalPoints: number;
  rank: number;
  platformStats: {
    TELEGRAM: PlatformStats;
    DISCORD: PlatformStats;
    TWITTER: PlatformStats;
  };
  recentActivity: {
    platform: string;
    type: string;
    points: number;
    description: string;
    timestamp: string;
  }[];
}

export default function Community() {
  const { publicKey } = useWallet();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      if (!publicKey) {
        navigate("/register");
        return;
      }

      try {
        const stats = await statsApi.getUserStats(publicKey.toString());
        // Transform UserStats into UserData format
        const transformedData: UserData = {
          totalPoints: stats.totalPoints,
          rank: stats.rank,
          platformStats: {
            TELEGRAM: {
              points: stats.platforms.telegram?.points || 0,
              rank: 0, // Default value since UserStats doesn't provide platform-specific rank
              engagements: stats.platforms.telegram?.interactions || 0,
              streak: 0, // Default value since UserStats doesn't provide streak
              history: [] // Default empty history since UserStats doesn't provide history
            },
            DISCORD: {
              points: stats.platforms.discord?.points || 0,
              rank: 0,
              engagements: stats.platforms.discord?.interactions || 0,
              streak: 0,
              history: []
            },
            TWITTER: {
              points: stats.platforms.twitter?.points || 0,
              rank: 0,
              engagements: stats.platforms.twitter?.interactions || 0,
              streak: 0,
              history: []
            }
          },
          recentActivity: [] // Default empty array since UserStats doesn't provide activity
        };
        setUserData(transformedData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [publicKey, navigate]);

  if (loading) return <LoadingState />;
  if (error) return <ErrorState message={error} />;
  if (!userData) return null;

  const StatCard = ({
    title,
    value,
    icon: Icon,
    color,
  }: {
    title: string;
    value: string | number;
    icon: React.ElementType;
    color: string;
  }) => (
    <div className="bg-black/50 backdrop-blur-lg p-6 rounded-lg border border-soless-blue/40">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-gray-300 font-medium">{title}</h3>
        <Icon className={`h-5 w-5 ${color}`} />
      </div>
      <p className="text-2xl font-bold text-white">{value}</p>
    </div>
  );

  const PlatformEngagement = ({
    platform,
    stats,
  }: {
    platform: string;
    stats: PlatformStats;
  }) => (
    <div className="bg-black/50 backdrop-blur-lg p-6 rounded-lg border border-soless-blue/40">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-xl font-bold text-soless-blue">{platform}</h3>
        <div className="flex items-center space-x-2">
          <Flame
            className={stats.streak > 0 ? "text-orange-400" : "text-gray-600"}
            size={16}
          />
          <span className="text-sm text-gray-400">{stats.streak}d streak</span>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-6">
        <div>
          <p className="text-gray-400 text-sm">Points</p>
          <p className="text-white text-lg font-medium">{stats.points}</p>
        </div>
        <div>
          <p className="text-gray-400 text-sm">Rank</p>
          <p className="text-white text-lg font-medium">#{stats.rank}</p>
        </div>
        <div>
          <p className="text-gray-400 text-sm">Engagements</p>
          <p className="text-white text-lg font-medium">{stats.engagements}</p>
        </div>
      </div>

      <div className="h-48">
        <LineChart
          width={350}
          height={200}
          data={stats.history}
          margin={{ top: 5, right: 20, left: 0, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#1f2937" />
          <XAxis dataKey="date" stroke="#9ca3af" />
          <YAxis stroke="#9ca3af" />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              border: "1px solid rgba(59, 130, 246, 0.4)",
            }}
          />
          <Line type="monotone" dataKey="points" stroke="#3b82f6" />
        </LineChart>
      </div>
    </div>
  );

  const ActivityItem = ({
    activity,
  }: {
    activity: UserData["recentActivity"][0];
  }) => (
    <div className="flex items-center justify-between py-3 border-b border-soless-blue/20">
      <div className="flex items-center space-x-3">
        <div
          className={`w-8 h-8 rounded-full flex items-center justify-center bg-soless-blue/20`}
        >
          {activity.platform === "TELEGRAM" && (
            <MessageCircle size={16} className="text-blue-400" />
          )}
          {activity.platform === "DISCORD" && (
            <MessageCircle size={16} className="text-indigo-400" />
          )}
          {activity.platform === "TWITTER" && (
            <Share2 size={16} className="text-sky-400" />
          )}
        </div>
        <div>
          <p className="text-white text-sm font-medium">{activity.type}</p>
          <p className="text-gray-400 text-xs">{activity.description}</p>
        </div>
      </div>
      <div className="text-right">
        <p className="text-green-400 text-sm font-medium">+{activity.points}</p>
        <p className="text-gray-400 text-xs">{activity.timestamp}</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-black pt-16">
      <div className="w-full">
        <img
          src="/assets/images/WordBanner.png"
          alt="SOLess Banner"
          className="w-full h-auto"
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-8">
          <h1 className="text-4xl font-bold text-soless-blue">
            Community Dashboard
          </h1>
          <InviteSection wallet={publicKey?.toString() || ""} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <StatCard
            title="Total Points"
            value={userData.totalPoints}
            icon={Trophy}
            color="text-yellow-400"
          />
          <StatCard
            title="Global Rank"
            value={`#${userData.rank}`}
            icon={Star}
            color="text-purple-400"
          />
          <StatCard
            title="Active Platforms"
            value={Object.keys(userData.platformStats).length}
            icon={Users}
            color="text-soless-blue"
          />
          <StatCard
            title="Best Streak"
            value={Math.max(
              userData.platformStats.TELEGRAM.streak,
              userData.platformStats.DISCORD.streak,
              userData.platformStats.TWITTER.streak
            )}
            icon={Flame}
            color="text-orange-400"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
          <PlatformEngagement
            platform="Telegram"
            stats={userData.platformStats.TELEGRAM}
          />
          <PlatformEngagement
            platform="Discord"
            stats={userData.platformStats.DISCORD}
          />
          <PlatformEngagement
            platform="Twitter"
            stats={userData.platformStats.TWITTER}
          />
        </div>

        <div className="bg-black/50 backdrop-blur-lg rounded-lg border border-soless-blue/40 p-6">
          <h2 className="text-2xl font-bold text-soless-blue mb-4">
            Recent Activity
          </h2>
          <div className="space-y-1">
            {userData.recentActivity.map((activity, index) => (
              <ActivityItem key={index} activity={activity} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
